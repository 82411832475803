/**
 * This file is part of Keith-mifsud.me
 *
 * @licence Please view the Licence file supplied with this source code.
 *
 * @author Keith Mifsud <https://www.keith-mifsud.me>
 *
 * @copyright Keith Mifsud 2018 <mifsud.k@gmail.com>
 *
 * @since   2.0
 * @version 2.0 Initial Release
 */
import React from 'react'
import DefaultLayout from './../layouts/Default'
import SEO from './../components/Seo'
import config from './../data/SiteConfig'
import FullSubscriptionForm from './../components/SubscribeForm/Full'
import Icon from '../components/Icon'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const SubscribePage = () => (
  <DefaultLayout className={`page-subscribe`}>
    <SEO
      pageType={`webpage`}
      pageTitle={`Subscribe`}
      fullPageTitle={`Subscribe to the Mailing List`}
      pageDescription={`Receive email updates from Keith Mifsud. By subscribing to the mailing list, I will notify you when I publish new articles and free open source libraries for you to use in your projects. I write about coding, software engineering, best practices and related topics.`}
      pageImage={config.siteImage}
      pagePath={`/subscribe`}
    />
    <section className="subscribe-form pt-lg-12 pt-10" style={{ minHeight: '100vh' }}>
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-4 offset-lg-4 text-center">
            <Icon type={`Mailbox`}/>
            <h1 className={`pt-lg-3`}>Subscribe</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-8 offset-lg-2 text-center pt-lg-5 pt-5">
            <p>Receive email updates from Keith Mifsud. By subscribing to the mailing list, I will notify you when I
              publish new articles and free open source libraries for you to use in your projects. I write about coding,
              software engineering, best practices and related topics.</p>
          </div>
        </div>
        <div className="row pt-lg-0 pt-3 justify-content-center">
          <div className="col-lg-2 col-4 text-center">
            <p className={`icons`}>
              <a
                href={config.defaultAuthor.socialProfiles.twitter}
                title={`Follow Keith Mifsud on Twitter`}
                target={`_blank`}
                rel={`noopener noreferrer`}
                className={`mr-3 hover-darken`}
                style={{ fontSize: '1.6rem' }}
              ><FontAwesomeIcon icon={['fab', 'twitter']}/>
              </a>
              <a
                href={config.siteUrl + config.rssFeedPath}
                title={`Get RSS feed`}
                target={`_blank`}
                rel={`noopener noreferrer`}
                className={`hover-darken`}
                style={{ fontSize: '1.6rem' }}
              ><FontAwesomeIcon icon={['fas', 'rss']}/>
              </a>
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-6 offset-lg-3 pt-lg-5">
            <FullSubscriptionForm pagePath={`/subscribe`}/>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-6 offset-lg-3 text-center">
            <p>
              <small>I will not share your email address with anyone, and you can unsubscribe at any time.</small>
            </p>
            <p>
              <small>View the <Link to={'/privacy-policy'} className={`hover-none hover-darken`}>privacy
                policy</Link> for more information.
              </small>
            </p>
          </div>
        </div>
      </div>
    </section>
  </DefaultLayout>
)

export default SubscribePage
