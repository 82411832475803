/**
 * This file is part of Keith-mifsud.me
 *
 * @licence Please view the Licence file supplied with this source code.
 *
 * @author Keith Mifsud <https://www.keith-mifsud.me>
 *
 * @copyright Keith Mifsud 2019 <mifsud.k@gmail.com>
 *
 * @since   2.0
 * @version 2.0 Second Major Release
 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import addToMailchimp from 'gatsby-plugin-mailchimp'

/**
 * Mailing list subscription form with all the fields.
 */
class FullSubscriptionForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      subscribed: false,
      emailAddress: '',
      firstName: '',
      lastName: '',
      errorMessage: '',
      successMessage: ''
    }
  }
  updateEmailAddress = event => {
    this.setState({
      emailAddress: event.target.value
    })
  }
  updateFirstName = event => {
    this.setState({
      firstName: event.target.value
    })
  }
  updateLastName = event => {
    this.setState({
      lastName: event.target.value
    })
  }
  subscribe = async event => {
    event.preventDefault()
    addToMailchimp(this.state.emailAddress, {
      PATHNAME: this.props.pagePath,
      FNAME: this.state.firstName,
      LNAME: this.state.lastName,
    }).then(data => {
      if (data.result === 'success') {
        let message = 'Thank you for subscribing!'
        this.setState({ successMessage: message })
        this.setState({ subscribed: true })
      } else {
        this.setState({ errorMessage: data.msg })
      }
    })
  }
  renderErrorMarkup = () => {
    return { __html: this.state.errorMessage }
  }
  render () {
    return (
      <form onSubmit={this.subscribe}>
        {this.state.subscribed ? (
          <div
            className="alert alert-success alert-dismissible fade show"
            role={`alert`}
          >
            {this.state.successMessage}
            <button className="close" data-dismiss="alert" aria-label={`Close`}>
              <span aria-hidden={`true`}>&times;</span>
            </button>
          </div>
        ) : (null)}
        {this.state.errorMessage ? (
          <div
            className="alert alert-danger alert-dismissible fade show"
            role={`alert`}
          >
            <div dangerouslySetInnerHTML={this.renderErrorMarkup()}></div>
            <button className="close" data-dismiss="alert" aria-label={`Close`}>
              <span aria-hidden={`true`}>&times;</span>
            </button>
          </div>
        ) : (null)}

        <div className="form-group">
          <input
            type="text"
            name={`firstName`}
            placeholder={`Your first name`}
            onChange={this.updateFirstName}
            className={`form-control`}
          />
        </div>

        <div className="form-group">
          <input
            type="text"
            name={`lastName`}
            placeholder={`Your last name`}
            onChange={this.updateLastName}
            className={`form-control`}
          />
        </div>

        <div className="form-group">
          <input
            type="text"
            name={`emailAddress`}
            placeholder={`Your email address (required)`}
            onChange={this.updateEmailAddress}
            className={`form-control`}
          />
        </div>

        <div className="form-group">
          <button
            className={`btn btn-warning btn-block`}
          >
            Subscribe
          </button>
        </div>
      </form>
    )
  }
}

FullSubscriptionForm.propTypes = {
  pagePath: PropTypes.string.isRequired,
}

export default FullSubscriptionForm
